import styled from "styled-components";
import { useAppContext } from "../context/context";
import { Ranking } from "./ranking";
import { DjList } from "./djLists";

const UserBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  background-color: rgba(255, 255, 255, 0.529);
  border-radius: 5px;
  width: 250px;
`;
const UserImg = styled.div`
  width: 200px;
  height: 200px;

  background-color: black;
  border-radius: 25px;
`;
const Container = styled.div`
  display: flex;
  padding: 50px;
  padding-top: 150px;
  @media screen and (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
    padding: 20px;
    padding-top: 150px;
  }
  gap: 20px;
`;
const UserName = styled.div`
  color: black;
  text-align: center;
  margin-top: 20px;
  font-size: 25px;
  font-weight: 500;
`;

const LikeHearth = styled.div`
  background-image: url("/img/cuore.webp");
  background-size: contain;
  background-position: left;
  background-repeat: no-repeat;
  height: 80px;
  width: 80px;
`;

const LikeCounter = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 20px;
  gap: 10px;
  color: black;
  font-size: 20px;
`;

const RankingContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-start;
  flex-direction: column;
  color: black;
  h1 {
    text-align: center;
  }
`;

function UserDetails() {
  const { usedVotes, userDetail } = useAppContext();

  return (
    <>
      <UserBox>
        <UserImg></UserImg>
        <UserName>{userDetail?.user_name}</UserName>
        <LikeCounter>
          <LikeHearth />
          <div>
            <b> {usedVotes}/50 </b>
            <br />I TUOI LIKE
          </div>
        </LikeCounter>
      </UserBox>
    </>
  );
}

export function VotingPage() {
  return (
    <>
      {" "}
      <Container>
        <UserDetails />
        <RankingContainer>
          <h1>
            Classifica settimanale <br />
            Vota per supportare la tua DJ prefertita!
          </h1>
          <Ranking />
        </RankingContainer>
      </Container>
      <DjList></DjList>
    </>
  );
}
