import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  age: { input: any; output: any; }
  bigint: { input: any; output: any; }
  gender: { input: any; output: any; }
  timestamptz: { input: any; output: any; }
  uuid: { input: any; output: any; }
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']['input']>;
  _gt?: InputMaybe<Scalars['String']['input']>;
  _gte?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']['input']>;
  _in?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']['input']>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']['input']>;
  _lt?: InputMaybe<Scalars['String']['input']>;
  _lte?: InputMaybe<Scalars['String']['input']>;
  _neq?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']['input']>;
  _nin?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']['input']>;
};

/** Boolean expression to compare columns of type "age". All fields are combined with logical 'AND'. */
export type Age_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['age']['input']>;
  _gt?: InputMaybe<Scalars['age']['input']>;
  _gte?: InputMaybe<Scalars['age']['input']>;
  _in?: InputMaybe<Array<Scalars['age']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['age']['input']>;
  _lte?: InputMaybe<Scalars['age']['input']>;
  _neq?: InputMaybe<Scalars['age']['input']>;
  _nin?: InputMaybe<Array<Scalars['age']['input']>>;
};

/** columns and relationships of "artist_votes_count" */
export type Artist_Votes_Count = {
  __typename?: 'artist_votes_count';
  artist_id?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  votecount_artist?: Maybe<Artists>;
  votes_count?: Maybe<Scalars['bigint']['output']>;
};

/** aggregated selection of "artist_votes_count" */
export type Artist_Votes_Count_Aggregate = {
  __typename?: 'artist_votes_count_aggregate';
  aggregate?: Maybe<Artist_Votes_Count_Aggregate_Fields>;
  nodes: Array<Artist_Votes_Count>;
};

/** aggregate fields of "artist_votes_count" */
export type Artist_Votes_Count_Aggregate_Fields = {
  __typename?: 'artist_votes_count_aggregate_fields';
  avg?: Maybe<Artist_Votes_Count_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Artist_Votes_Count_Max_Fields>;
  min?: Maybe<Artist_Votes_Count_Min_Fields>;
  stddev?: Maybe<Artist_Votes_Count_Stddev_Fields>;
  stddev_pop?: Maybe<Artist_Votes_Count_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Artist_Votes_Count_Stddev_Samp_Fields>;
  sum?: Maybe<Artist_Votes_Count_Sum_Fields>;
  var_pop?: Maybe<Artist_Votes_Count_Var_Pop_Fields>;
  var_samp?: Maybe<Artist_Votes_Count_Var_Samp_Fields>;
  variance?: Maybe<Artist_Votes_Count_Variance_Fields>;
};


/** aggregate fields of "artist_votes_count" */
export type Artist_Votes_Count_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Artist_Votes_Count_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Artist_Votes_Count_Avg_Fields = {
  __typename?: 'artist_votes_count_avg_fields';
  votes_count?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "artist_votes_count". All fields are combined with a logical 'AND'. */
export type Artist_Votes_Count_Bool_Exp = {
  _and?: InputMaybe<Array<Artist_Votes_Count_Bool_Exp>>;
  _not?: InputMaybe<Artist_Votes_Count_Bool_Exp>;
  _or?: InputMaybe<Array<Artist_Votes_Count_Bool_Exp>>;
  artist_id?: InputMaybe<String_Comparison_Exp>;
  votecount_artist?: InputMaybe<Artists_Bool_Exp>;
  votes_count?: InputMaybe<Bigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type Artist_Votes_Count_Max_Fields = {
  __typename?: 'artist_votes_count_max_fields';
  artist_id?: Maybe<Scalars['String']['output']>;
  votes_count?: Maybe<Scalars['bigint']['output']>;
};

/** aggregate min on columns */
export type Artist_Votes_Count_Min_Fields = {
  __typename?: 'artist_votes_count_min_fields';
  artist_id?: Maybe<Scalars['String']['output']>;
  votes_count?: Maybe<Scalars['bigint']['output']>;
};

/** Ordering options when selecting data from "artist_votes_count". */
export type Artist_Votes_Count_Order_By = {
  artist_id?: InputMaybe<Order_By>;
  votecount_artist?: InputMaybe<Artists_Order_By>;
  votes_count?: InputMaybe<Order_By>;
};

/** select columns of table "artist_votes_count" */
export enum Artist_Votes_Count_Select_Column {
  /** column name */
  ArtistId = 'artist_id',
  /** column name */
  VotesCount = 'votes_count'
}

/** aggregate stddev on columns */
export type Artist_Votes_Count_Stddev_Fields = {
  __typename?: 'artist_votes_count_stddev_fields';
  votes_count?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Artist_Votes_Count_Stddev_Pop_Fields = {
  __typename?: 'artist_votes_count_stddev_pop_fields';
  votes_count?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Artist_Votes_Count_Stddev_Samp_Fields = {
  __typename?: 'artist_votes_count_stddev_samp_fields';
  votes_count?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "artist_votes_count" */
export type Artist_Votes_Count_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Artist_Votes_Count_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Artist_Votes_Count_Stream_Cursor_Value_Input = {
  artist_id?: InputMaybe<Scalars['String']['input']>;
  votes_count?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate sum on columns */
export type Artist_Votes_Count_Sum_Fields = {
  __typename?: 'artist_votes_count_sum_fields';
  votes_count?: Maybe<Scalars['bigint']['output']>;
};

/** aggregate var_pop on columns */
export type Artist_Votes_Count_Var_Pop_Fields = {
  __typename?: 'artist_votes_count_var_pop_fields';
  votes_count?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Artist_Votes_Count_Var_Samp_Fields = {
  __typename?: 'artist_votes_count_var_samp_fields';
  votes_count?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Artist_Votes_Count_Variance_Fields = {
  __typename?: 'artist_votes_count_variance_fields';
  votes_count?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "artists" */
export type Artists = {
  __typename?: 'artists';
  description?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

/** aggregated selection of "artists" */
export type Artists_Aggregate = {
  __typename?: 'artists_aggregate';
  aggregate?: Maybe<Artists_Aggregate_Fields>;
  nodes: Array<Artists>;
};

/** aggregate fields of "artists" */
export type Artists_Aggregate_Fields = {
  __typename?: 'artists_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Artists_Max_Fields>;
  min?: Maybe<Artists_Min_Fields>;
};


/** aggregate fields of "artists" */
export type Artists_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Artists_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "artists". All fields are combined with a logical 'AND'. */
export type Artists_Bool_Exp = {
  _and?: InputMaybe<Array<Artists_Bool_Exp>>;
  _not?: InputMaybe<Artists_Bool_Exp>;
  _or?: InputMaybe<Array<Artists_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "artists" */
export enum Artists_Constraint {
  /** unique or primary key constraint on columns "name" */
  ArtistsPkey = 'artists_pkey'
}

export enum Artists_Enum {
  Dizzy = 'dizzy',
  Gloria = 'gloria',
  Ika = 'ika',
  Nikita = 'nikita'
}

/** Boolean expression to compare columns of type "artists_enum". All fields are combined with logical 'AND'. */
export type Artists_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Artists_Enum>;
  _in?: InputMaybe<Array<Artists_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Artists_Enum>;
  _nin?: InputMaybe<Array<Artists_Enum>>;
};

/** input type for inserting data into table "artists" */
export type Artists_Insert_Input = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Artists_Max_Fields = {
  __typename?: 'artists_max_fields';
  description?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Artists_Min_Fields = {
  __typename?: 'artists_min_fields';
  description?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "artists" */
export type Artists_Mutation_Response = {
  __typename?: 'artists_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Artists>;
};

/** on_conflict condition type for table "artists" */
export type Artists_On_Conflict = {
  constraint: Artists_Constraint;
  update_columns?: Array<Artists_Update_Column>;
  where?: InputMaybe<Artists_Bool_Exp>;
};

/** Ordering options when selecting data from "artists". */
export type Artists_Order_By = {
  description?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: artists */
export type Artists_Pk_Columns_Input = {
  name: Scalars['String']['input'];
};

/** select columns of table "artists" */
export enum Artists_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "artists" */
export type Artists_Set_Input = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "artists" */
export type Artists_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Artists_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Artists_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "artists" */
export enum Artists_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name'
}

export type Artists_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Artists_Set_Input>;
  /** filter the rows which have to be updated */
  where: Artists_Bool_Exp;
};

/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['bigint']['input']>;
  _gt?: InputMaybe<Scalars['bigint']['input']>;
  _gte?: InputMaybe<Scalars['bigint']['input']>;
  _in?: InputMaybe<Array<Scalars['bigint']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['bigint']['input']>;
  _lte?: InputMaybe<Scalars['bigint']['input']>;
  _neq?: InputMaybe<Scalars['bigint']['input']>;
  _nin?: InputMaybe<Array<Scalars['bigint']['input']>>;
};

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC'
}

/** Boolean expression to compare columns of type "gender". All fields are combined with logical 'AND'. */
export type Gender_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['gender']['input']>;
  _gt?: InputMaybe<Scalars['gender']['input']>;
  _gte?: InputMaybe<Scalars['gender']['input']>;
  _in?: InputMaybe<Array<Scalars['gender']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['gender']['input']>;
  _lte?: InputMaybe<Scalars['gender']['input']>;
  _neq?: InputMaybe<Scalars['gender']['input']>;
  _nin?: InputMaybe<Array<Scalars['gender']['input']>>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  /** delete data from the table: "artists" */
  delete_artists?: Maybe<Artists_Mutation_Response>;
  /** delete single row from the table: "artists" */
  delete_artists_by_pk?: Maybe<Artists>;
  /** delete data from the table: "users" */
  delete_users?: Maybe<Users_Mutation_Response>;
  /** delete single row from the table: "users" */
  delete_users_by_pk?: Maybe<Users>;
  /** delete data from the table: "votes" */
  delete_votes?: Maybe<Votes_Mutation_Response>;
  /** delete single row from the table: "votes" */
  delete_votes_by_pk?: Maybe<Votes>;
  /** insert data into the table: "artists" */
  insert_artists?: Maybe<Artists_Mutation_Response>;
  /** insert a single row into the table: "artists" */
  insert_artists_one?: Maybe<Artists>;
  /** insert data into the table: "users" */
  insert_users?: Maybe<Users_Mutation_Response>;
  /** insert a single row into the table: "users" */
  insert_users_one?: Maybe<Users>;
  /** insert data into the table: "votes" */
  insert_votes?: Maybe<Votes_Mutation_Response>;
  /** insert a single row into the table: "votes" */
  insert_votes_one?: Maybe<Votes>;
  /** update data of the table: "artists" */
  update_artists?: Maybe<Artists_Mutation_Response>;
  /** update single row of the table: "artists" */
  update_artists_by_pk?: Maybe<Artists>;
  /** update multiples rows of table: "artists" */
  update_artists_many?: Maybe<Array<Maybe<Artists_Mutation_Response>>>;
  /** update data of the table: "users" */
  update_users?: Maybe<Users_Mutation_Response>;
  /** update single row of the table: "users" */
  update_users_by_pk?: Maybe<Users>;
  /** update multiples rows of table: "users" */
  update_users_many?: Maybe<Array<Maybe<Users_Mutation_Response>>>;
  /** update data of the table: "votes" */
  update_votes?: Maybe<Votes_Mutation_Response>;
  /** update single row of the table: "votes" */
  update_votes_by_pk?: Maybe<Votes>;
  /** update multiples rows of table: "votes" */
  update_votes_many?: Maybe<Array<Maybe<Votes_Mutation_Response>>>;
};


/** mutation root */
export type Mutation_RootDelete_ArtistsArgs = {
  where: Artists_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Artists_By_PkArgs = {
  name: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_UsersArgs = {
  where: Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Users_By_PkArgs = {
  user_id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_VotesArgs = {
  where: Votes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Votes_By_PkArgs = {
  vote_id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootInsert_ArtistsArgs = {
  objects: Array<Artists_Insert_Input>;
  on_conflict?: InputMaybe<Artists_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Artists_OneArgs = {
  object: Artists_Insert_Input;
  on_conflict?: InputMaybe<Artists_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UsersArgs = {
  objects: Array<Users_Insert_Input>;
  on_conflict?: InputMaybe<Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_OneArgs = {
  object: Users_Insert_Input;
  on_conflict?: InputMaybe<Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_VotesArgs = {
  objects: Array<Votes_Insert_Input>;
  on_conflict?: InputMaybe<Votes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Votes_OneArgs = {
  object: Votes_Insert_Input;
  on_conflict?: InputMaybe<Votes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootUpdate_ArtistsArgs = {
  _set?: InputMaybe<Artists_Set_Input>;
  where: Artists_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Artists_By_PkArgs = {
  _set?: InputMaybe<Artists_Set_Input>;
  pk_columns: Artists_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Artists_ManyArgs = {
  updates: Array<Artists_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_UsersArgs = {
  _set?: InputMaybe<Users_Set_Input>;
  where: Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Users_By_PkArgs = {
  _set?: InputMaybe<Users_Set_Input>;
  pk_columns: Users_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Users_ManyArgs = {
  updates: Array<Users_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_VotesArgs = {
  _set?: InputMaybe<Votes_Set_Input>;
  where: Votes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Votes_By_PkArgs = {
  _set?: InputMaybe<Votes_Set_Input>;
  pk_columns: Votes_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Votes_ManyArgs = {
  updates: Array<Votes_Updates>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

export type Query_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "artist_votes_count" */
  artist_votes_count: Array<Artist_Votes_Count>;
  /** fetch aggregated fields from the table: "artist_votes_count" */
  artist_votes_count_aggregate: Artist_Votes_Count_Aggregate;
  /** fetch data from the table: "artists" */
  artists: Array<Artists>;
  /** fetch aggregated fields from the table: "artists" */
  artists_aggregate: Artists_Aggregate;
  /** fetch data from the table: "artists" using primary key columns */
  artists_by_pk?: Maybe<Artists>;
  /** fetch data from the table: "user_weekly_votes" */
  user_weekly_votes: Array<User_Weekly_Votes>;
  /** fetch aggregated fields from the table: "user_weekly_votes" */
  user_weekly_votes_aggregate: User_Weekly_Votes_Aggregate;
  /** fetch data from the table: "users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "users" */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
  /** fetch data from the table: "votes" */
  votes: Array<Votes>;
  /** fetch aggregated fields from the table: "votes" */
  votes_aggregate: Votes_Aggregate;
  /** fetch data from the table: "votes" using primary key columns */
  votes_by_pk?: Maybe<Votes>;
};


export type Query_RootArtist_Votes_CountArgs = {
  distinct_on?: InputMaybe<Array<Artist_Votes_Count_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Artist_Votes_Count_Order_By>>;
  where?: InputMaybe<Artist_Votes_Count_Bool_Exp>;
};


export type Query_RootArtist_Votes_Count_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Artist_Votes_Count_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Artist_Votes_Count_Order_By>>;
  where?: InputMaybe<Artist_Votes_Count_Bool_Exp>;
};


export type Query_RootArtistsArgs = {
  distinct_on?: InputMaybe<Array<Artists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Artists_Order_By>>;
  where?: InputMaybe<Artists_Bool_Exp>;
};


export type Query_RootArtists_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Artists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Artists_Order_By>>;
  where?: InputMaybe<Artists_Bool_Exp>;
};


export type Query_RootArtists_By_PkArgs = {
  name: Scalars['String']['input'];
};


export type Query_RootUser_Weekly_VotesArgs = {
  distinct_on?: InputMaybe<Array<User_Weekly_Votes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Weekly_Votes_Order_By>>;
  where?: InputMaybe<User_Weekly_Votes_Bool_Exp>;
};


export type Query_RootUser_Weekly_Votes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Weekly_Votes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Weekly_Votes_Order_By>>;
  where?: InputMaybe<User_Weekly_Votes_Bool_Exp>;
};


export type Query_RootUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Query_RootUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Query_RootUsers_By_PkArgs = {
  user_id: Scalars['String']['input'];
};


export type Query_RootVotesArgs = {
  distinct_on?: InputMaybe<Array<Votes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Votes_Order_By>>;
  where?: InputMaybe<Votes_Bool_Exp>;
};


export type Query_RootVotes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Votes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Votes_Order_By>>;
  where?: InputMaybe<Votes_Bool_Exp>;
};


export type Query_RootVotes_By_PkArgs = {
  vote_id: Scalars['uuid']['input'];
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "artist_votes_count" */
  artist_votes_count: Array<Artist_Votes_Count>;
  /** fetch aggregated fields from the table: "artist_votes_count" */
  artist_votes_count_aggregate: Artist_Votes_Count_Aggregate;
  /** fetch data from the table in a streaming manner: "artist_votes_count" */
  artist_votes_count_stream: Array<Artist_Votes_Count>;
  /** fetch data from the table: "artists" */
  artists: Array<Artists>;
  /** fetch aggregated fields from the table: "artists" */
  artists_aggregate: Artists_Aggregate;
  /** fetch data from the table: "artists" using primary key columns */
  artists_by_pk?: Maybe<Artists>;
  /** fetch data from the table in a streaming manner: "artists" */
  artists_stream: Array<Artists>;
  /** fetch data from the table: "user_weekly_votes" */
  user_weekly_votes: Array<User_Weekly_Votes>;
  /** fetch aggregated fields from the table: "user_weekly_votes" */
  user_weekly_votes_aggregate: User_Weekly_Votes_Aggregate;
  /** fetch data from the table in a streaming manner: "user_weekly_votes" */
  user_weekly_votes_stream: Array<User_Weekly_Votes>;
  /** fetch data from the table: "users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "users" */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
  /** fetch data from the table in a streaming manner: "users" */
  users_stream: Array<Users>;
  /** fetch data from the table: "votes" */
  votes: Array<Votes>;
  /** fetch aggregated fields from the table: "votes" */
  votes_aggregate: Votes_Aggregate;
  /** fetch data from the table: "votes" using primary key columns */
  votes_by_pk?: Maybe<Votes>;
  /** fetch data from the table in a streaming manner: "votes" */
  votes_stream: Array<Votes>;
};


export type Subscription_RootArtist_Votes_CountArgs = {
  distinct_on?: InputMaybe<Array<Artist_Votes_Count_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Artist_Votes_Count_Order_By>>;
  where?: InputMaybe<Artist_Votes_Count_Bool_Exp>;
};


export type Subscription_RootArtist_Votes_Count_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Artist_Votes_Count_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Artist_Votes_Count_Order_By>>;
  where?: InputMaybe<Artist_Votes_Count_Bool_Exp>;
};


export type Subscription_RootArtist_Votes_Count_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Artist_Votes_Count_Stream_Cursor_Input>>;
  where?: InputMaybe<Artist_Votes_Count_Bool_Exp>;
};


export type Subscription_RootArtistsArgs = {
  distinct_on?: InputMaybe<Array<Artists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Artists_Order_By>>;
  where?: InputMaybe<Artists_Bool_Exp>;
};


export type Subscription_RootArtists_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Artists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Artists_Order_By>>;
  where?: InputMaybe<Artists_Bool_Exp>;
};


export type Subscription_RootArtists_By_PkArgs = {
  name: Scalars['String']['input'];
};


export type Subscription_RootArtists_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Artists_Stream_Cursor_Input>>;
  where?: InputMaybe<Artists_Bool_Exp>;
};


export type Subscription_RootUser_Weekly_VotesArgs = {
  distinct_on?: InputMaybe<Array<User_Weekly_Votes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Weekly_Votes_Order_By>>;
  where?: InputMaybe<User_Weekly_Votes_Bool_Exp>;
};


export type Subscription_RootUser_Weekly_Votes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Weekly_Votes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Weekly_Votes_Order_By>>;
  where?: InputMaybe<User_Weekly_Votes_Bool_Exp>;
};


export type Subscription_RootUser_Weekly_Votes_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<User_Weekly_Votes_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Weekly_Votes_Bool_Exp>;
};


export type Subscription_RootUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Subscription_RootUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Subscription_RootUsers_By_PkArgs = {
  user_id: Scalars['String']['input'];
};


export type Subscription_RootUsers_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Users_Stream_Cursor_Input>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Subscription_RootVotesArgs = {
  distinct_on?: InputMaybe<Array<Votes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Votes_Order_By>>;
  where?: InputMaybe<Votes_Bool_Exp>;
};


export type Subscription_RootVotes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Votes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Votes_Order_By>>;
  where?: InputMaybe<Votes_Bool_Exp>;
};


export type Subscription_RootVotes_By_PkArgs = {
  vote_id: Scalars['uuid']['input'];
};


export type Subscription_RootVotes_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Votes_Stream_Cursor_Input>>;
  where?: InputMaybe<Votes_Bool_Exp>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']['input']>;
  _gt?: InputMaybe<Scalars['timestamptz']['input']>;
  _gte?: InputMaybe<Scalars['timestamptz']['input']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['timestamptz']['input']>;
  _lte?: InputMaybe<Scalars['timestamptz']['input']>;
  _neq?: InputMaybe<Scalars['timestamptz']['input']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']['input']>>;
};

/** columns and relationships of "user_weekly_votes" */
export type User_Weekly_Votes = {
  __typename?: 'user_weekly_votes';
  user_id?: Maybe<Scalars['String']['output']>;
  weekly_votes?: Maybe<Scalars['bigint']['output']>;
};

/** aggregated selection of "user_weekly_votes" */
export type User_Weekly_Votes_Aggregate = {
  __typename?: 'user_weekly_votes_aggregate';
  aggregate?: Maybe<User_Weekly_Votes_Aggregate_Fields>;
  nodes: Array<User_Weekly_Votes>;
};

/** aggregate fields of "user_weekly_votes" */
export type User_Weekly_Votes_Aggregate_Fields = {
  __typename?: 'user_weekly_votes_aggregate_fields';
  avg?: Maybe<User_Weekly_Votes_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<User_Weekly_Votes_Max_Fields>;
  min?: Maybe<User_Weekly_Votes_Min_Fields>;
  stddev?: Maybe<User_Weekly_Votes_Stddev_Fields>;
  stddev_pop?: Maybe<User_Weekly_Votes_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Weekly_Votes_Stddev_Samp_Fields>;
  sum?: Maybe<User_Weekly_Votes_Sum_Fields>;
  var_pop?: Maybe<User_Weekly_Votes_Var_Pop_Fields>;
  var_samp?: Maybe<User_Weekly_Votes_Var_Samp_Fields>;
  variance?: Maybe<User_Weekly_Votes_Variance_Fields>;
};


/** aggregate fields of "user_weekly_votes" */
export type User_Weekly_Votes_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Weekly_Votes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type User_Weekly_Votes_Avg_Fields = {
  __typename?: 'user_weekly_votes_avg_fields';
  weekly_votes?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "user_weekly_votes". All fields are combined with a logical 'AND'. */
export type User_Weekly_Votes_Bool_Exp = {
  _and?: InputMaybe<Array<User_Weekly_Votes_Bool_Exp>>;
  _not?: InputMaybe<User_Weekly_Votes_Bool_Exp>;
  _or?: InputMaybe<Array<User_Weekly_Votes_Bool_Exp>>;
  user_id?: InputMaybe<String_Comparison_Exp>;
  weekly_votes?: InputMaybe<Bigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type User_Weekly_Votes_Max_Fields = {
  __typename?: 'user_weekly_votes_max_fields';
  user_id?: Maybe<Scalars['String']['output']>;
  weekly_votes?: Maybe<Scalars['bigint']['output']>;
};

/** aggregate min on columns */
export type User_Weekly_Votes_Min_Fields = {
  __typename?: 'user_weekly_votes_min_fields';
  user_id?: Maybe<Scalars['String']['output']>;
  weekly_votes?: Maybe<Scalars['bigint']['output']>;
};

/** Ordering options when selecting data from "user_weekly_votes". */
export type User_Weekly_Votes_Order_By = {
  user_id?: InputMaybe<Order_By>;
  weekly_votes?: InputMaybe<Order_By>;
};

/** select columns of table "user_weekly_votes" */
export enum User_Weekly_Votes_Select_Column {
  /** column name */
  UserId = 'user_id',
  /** column name */
  WeeklyVotes = 'weekly_votes'
}

/** aggregate stddev on columns */
export type User_Weekly_Votes_Stddev_Fields = {
  __typename?: 'user_weekly_votes_stddev_fields';
  weekly_votes?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type User_Weekly_Votes_Stddev_Pop_Fields = {
  __typename?: 'user_weekly_votes_stddev_pop_fields';
  weekly_votes?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type User_Weekly_Votes_Stddev_Samp_Fields = {
  __typename?: 'user_weekly_votes_stddev_samp_fields';
  weekly_votes?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "user_weekly_votes" */
export type User_Weekly_Votes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Weekly_Votes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Weekly_Votes_Stream_Cursor_Value_Input = {
  user_id?: InputMaybe<Scalars['String']['input']>;
  weekly_votes?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate sum on columns */
export type User_Weekly_Votes_Sum_Fields = {
  __typename?: 'user_weekly_votes_sum_fields';
  weekly_votes?: Maybe<Scalars['bigint']['output']>;
};

/** aggregate var_pop on columns */
export type User_Weekly_Votes_Var_Pop_Fields = {
  __typename?: 'user_weekly_votes_var_pop_fields';
  weekly_votes?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type User_Weekly_Votes_Var_Samp_Fields = {
  __typename?: 'user_weekly_votes_var_samp_fields';
  weekly_votes?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type User_Weekly_Votes_Variance_Fields = {
  __typename?: 'user_weekly_votes_variance_fields';
  weekly_votes?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "users" */
export type Users = {
  __typename?: 'users';
  age?: Maybe<Scalars['age']['output']>;
  avatar?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Scalars['gender']['output']>;
  user_id: Scalars['String']['output'];
  user_name?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "users" */
export type Users_Aggregate = {
  __typename?: 'users_aggregate';
  aggregate?: Maybe<Users_Aggregate_Fields>;
  nodes: Array<Users>;
};

/** aggregate fields of "users" */
export type Users_Aggregate_Fields = {
  __typename?: 'users_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Users_Max_Fields>;
  min?: Maybe<Users_Min_Fields>;
};


/** aggregate fields of "users" */
export type Users_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "users". All fields are combined with a logical 'AND'. */
export type Users_Bool_Exp = {
  _and?: InputMaybe<Array<Users_Bool_Exp>>;
  _not?: InputMaybe<Users_Bool_Exp>;
  _or?: InputMaybe<Array<Users_Bool_Exp>>;
  age?: InputMaybe<Age_Comparison_Exp>;
  avatar?: InputMaybe<String_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  gender?: InputMaybe<Gender_Comparison_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
  user_name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "users" */
export enum Users_Constraint {
  /** unique or primary key constraint on columns "user_id" */
  UsersPkey = 'users_pkey'
}

/** input type for inserting data into table "users" */
export type Users_Insert_Input = {
  age?: InputMaybe<Scalars['age']['input']>;
  avatar?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Scalars['gender']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
  user_name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Users_Max_Fields = {
  __typename?: 'users_max_fields';
  age?: Maybe<Scalars['age']['output']>;
  avatar?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Scalars['gender']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
  user_name?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Users_Min_Fields = {
  __typename?: 'users_min_fields';
  age?: Maybe<Scalars['age']['output']>;
  avatar?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Scalars['gender']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
  user_name?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "users" */
export type Users_Mutation_Response = {
  __typename?: 'users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Users>;
};

/** on_conflict condition type for table "users" */
export type Users_On_Conflict = {
  constraint: Users_Constraint;
  update_columns?: Array<Users_Update_Column>;
  where?: InputMaybe<Users_Bool_Exp>;
};

/** Ordering options when selecting data from "users". */
export type Users_Order_By = {
  age?: InputMaybe<Order_By>;
  avatar?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  gender?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  user_name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: users */
export type Users_Pk_Columns_Input = {
  user_id: Scalars['String']['input'];
};

/** select columns of table "users" */
export enum Users_Select_Column {
  /** column name */
  Age = 'age',
  /** column name */
  Avatar = 'avatar',
  /** column name */
  Email = 'email',
  /** column name */
  Gender = 'gender',
  /** column name */
  UserId = 'user_id',
  /** column name */
  UserName = 'user_name'
}

/** input type for updating data in table "users" */
export type Users_Set_Input = {
  age?: InputMaybe<Scalars['age']['input']>;
  avatar?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Scalars['gender']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
  user_name?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "users" */
export type Users_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Users_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Users_Stream_Cursor_Value_Input = {
  age?: InputMaybe<Scalars['age']['input']>;
  avatar?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Scalars['gender']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
  user_name?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "users" */
export enum Users_Update_Column {
  /** column name */
  Age = 'age',
  /** column name */
  Avatar = 'avatar',
  /** column name */
  Email = 'email',
  /** column name */
  Gender = 'gender',
  /** column name */
  UserId = 'user_id',
  /** column name */
  UserName = 'user_name'
}

export type Users_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Users_Set_Input>;
  /** filter the rows which have to be updated */
  where: Users_Bool_Exp;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['uuid']['input']>;
  _gt?: InputMaybe<Scalars['uuid']['input']>;
  _gte?: InputMaybe<Scalars['uuid']['input']>;
  _in?: InputMaybe<Array<Scalars['uuid']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['uuid']['input']>;
  _lte?: InputMaybe<Scalars['uuid']['input']>;
  _neq?: InputMaybe<Scalars['uuid']['input']>;
  _nin?: InputMaybe<Array<Scalars['uuid']['input']>>;
};

/** columns and relationships of "votes" */
export type Votes = {
  __typename?: 'votes';
  user_id: Scalars['String']['output'];
  vote_id: Scalars['uuid']['output'];
  voted_at: Scalars['timestamptz']['output'];
  voted_for: Artists_Enum;
};

/** aggregated selection of "votes" */
export type Votes_Aggregate = {
  __typename?: 'votes_aggregate';
  aggregate?: Maybe<Votes_Aggregate_Fields>;
  nodes: Array<Votes>;
};

/** aggregate fields of "votes" */
export type Votes_Aggregate_Fields = {
  __typename?: 'votes_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Votes_Max_Fields>;
  min?: Maybe<Votes_Min_Fields>;
};


/** aggregate fields of "votes" */
export type Votes_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Votes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "votes". All fields are combined with a logical 'AND'. */
export type Votes_Bool_Exp = {
  _and?: InputMaybe<Array<Votes_Bool_Exp>>;
  _not?: InputMaybe<Votes_Bool_Exp>;
  _or?: InputMaybe<Array<Votes_Bool_Exp>>;
  user_id?: InputMaybe<String_Comparison_Exp>;
  vote_id?: InputMaybe<Uuid_Comparison_Exp>;
  voted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  voted_for?: InputMaybe<Artists_Enum_Comparison_Exp>;
};

/** unique or primary key constraints on table "votes" */
export enum Votes_Constraint {
  /** unique or primary key constraint on columns "vote_id" */
  VotesPkey = 'votes_pkey'
}

/** input type for inserting data into table "votes" */
export type Votes_Insert_Input = {
  user_id?: InputMaybe<Scalars['String']['input']>;
  vote_id?: InputMaybe<Scalars['uuid']['input']>;
  voted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  voted_for?: InputMaybe<Artists_Enum>;
};

/** aggregate max on columns */
export type Votes_Max_Fields = {
  __typename?: 'votes_max_fields';
  user_id?: Maybe<Scalars['String']['output']>;
  vote_id?: Maybe<Scalars['uuid']['output']>;
  voted_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type Votes_Min_Fields = {
  __typename?: 'votes_min_fields';
  user_id?: Maybe<Scalars['String']['output']>;
  vote_id?: Maybe<Scalars['uuid']['output']>;
  voted_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "votes" */
export type Votes_Mutation_Response = {
  __typename?: 'votes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Votes>;
};

/** on_conflict condition type for table "votes" */
export type Votes_On_Conflict = {
  constraint: Votes_Constraint;
  update_columns?: Array<Votes_Update_Column>;
  where?: InputMaybe<Votes_Bool_Exp>;
};

/** Ordering options when selecting data from "votes". */
export type Votes_Order_By = {
  user_id?: InputMaybe<Order_By>;
  vote_id?: InputMaybe<Order_By>;
  voted_at?: InputMaybe<Order_By>;
  voted_for?: InputMaybe<Order_By>;
};

/** primary key columns input for table: votes */
export type Votes_Pk_Columns_Input = {
  vote_id: Scalars['uuid']['input'];
};

/** select columns of table "votes" */
export enum Votes_Select_Column {
  /** column name */
  UserId = 'user_id',
  /** column name */
  VoteId = 'vote_id',
  /** column name */
  VotedAt = 'voted_at',
  /** column name */
  VotedFor = 'voted_for'
}

/** input type for updating data in table "votes" */
export type Votes_Set_Input = {
  user_id?: InputMaybe<Scalars['String']['input']>;
  vote_id?: InputMaybe<Scalars['uuid']['input']>;
  voted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  voted_for?: InputMaybe<Artists_Enum>;
};

/** Streaming cursor of the table "votes" */
export type Votes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Votes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Votes_Stream_Cursor_Value_Input = {
  user_id?: InputMaybe<Scalars['String']['input']>;
  vote_id?: InputMaybe<Scalars['uuid']['input']>;
  voted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  voted_for?: InputMaybe<Artists_Enum>;
};

/** update columns of table "votes" */
export enum Votes_Update_Column {
  /** column name */
  UserId = 'user_id',
  /** column name */
  VoteId = 'vote_id',
  /** column name */
  VotedAt = 'voted_at',
  /** column name */
  VotedFor = 'voted_for'
}

export type Votes_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Votes_Set_Input>;
  /** filter the rows which have to be updated */
  where: Votes_Bool_Exp;
};

export type VotingStatusQueryVariables = Exact<{ [key: string]: never; }>;


export type VotingStatusQuery = { __typename?: 'query_root', user_weekly_votes: Array<{ __typename?: 'user_weekly_votes', user_id?: string | null, weekly_votes?: any | null }>, artist_votes_count: Array<{ __typename?: 'artist_votes_count', artist_id?: string | null, votes_count?: any | null }> };

export type UserDetailsQueryVariables = Exact<{
  user_id: Scalars['String']['input'];
}>;


export type UserDetailsQuery = { __typename?: 'query_root', users_by_pk?: { __typename?: 'users', avatar?: string | null, age?: any | null, email?: string | null, gender?: any | null, user_name?: string | null } | null };

export type VoteForMutationVariables = Exact<{
  voted_for: Artists_Enum;
}>;


export type VoteForMutation = { __typename?: 'mutation_root', insert_votes?: { __typename?: 'votes_mutation_response', affected_rows: number } | null };


export const VotingStatusDocument = gql`
    query VotingStatus {
  user_weekly_votes {
    user_id
    weekly_votes
  }
  artist_votes_count {
    artist_id
    votes_count
  }
}
    `;

export function useVotingStatusQuery(options?: Omit<Urql.UseQueryArgs<VotingStatusQueryVariables>, 'query'>) {
  return Urql.useQuery<VotingStatusQuery, VotingStatusQueryVariables>({ query: VotingStatusDocument, ...options });
};
export const UserDetailsDocument = gql`
    query UserDetails($user_id: String!) {
  users_by_pk(user_id: $user_id) {
    avatar
    age
    email
    gender
    user_name
  }
}
    `;

export function useUserDetailsQuery(options: Omit<Urql.UseQueryArgs<UserDetailsQueryVariables>, 'query'>) {
  return Urql.useQuery<UserDetailsQuery, UserDetailsQueryVariables>({ query: UserDetailsDocument, ...options });
};
export const VoteForDocument = gql`
    mutation VoteFor($voted_for: artists_enum!) {
  insert_votes(objects: {voted_for: $voted_for}) {
    affected_rows
  }
}
    `;

export function useVoteForMutation() {
  return Urql.useMutation<VoteForMutation, VoteForMutationVariables>(VoteForDocument);
};