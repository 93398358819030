import { useForm } from "@formspree/react";
import { useState } from "react";
import styled from "styled-components";
import { Button } from "../components";
import { DjBox } from "./djBox";
import { Artists_Enum } from "../generated/graphql";
import { useAppContext } from "../context/context";
import { Link } from "react-router-dom";
const Container = styled.form`
  display: flex;
  margin-top: 50px;
  gap: 50px;
  align-items: center;
  justify-content: center;
  h1 {
    font-weight: 500;
    font-size: 45px;
    margin: 0;
  }
  @media screen and (max-width: 1100px) {
    flex-direction: column;
  }
`;

const FormToFill = styled.div`
  flex: 1;
  z-index: 5;
  max-width: 500px;
  & button {
    width: 100%;
  }
`;
const DjGrid = styled.div`
  flex: 2;
  padding: 20px;

  z-index: 5;
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  row-gap: 20px;
  justify-items: center;
  column-gap: 20px;
  @media screen and (max-width: 800px) {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(4, 1fr);
  }
`;

const ThreeStepsContainer = styled.div`
  display: flex;
  & > div {
    flex: 1;
  }
  gap: 10px;
  p {
    height: 100px;
  }
  @media screen and (max-width: 800px) {
    flex-direction: column;
    gap: 50px;
    p {
      height: auto;
    }
  }
`;

const VotingNotice = styled.div`
  color: var(--dj-white);
  font-size: small;
  text-align: center;
  position: relative;
  z-index: 5;
`;

const Select = styled.select`
  font-family: "Montserrat";
  color: #ff904e;
  border: 2px solid white;
  padding: 10px 20px;
  font-size: 20px;
  position: relative;
  width: 100%;
  margin-top: 10px;
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M7 10l5 5 5-5z' fill='%23ff904e' /%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 24px;
`;

const ThankYou = styled.div`
  z-index: 500;
  position: relative;

  background-color: var(--dj-white);
  padding: 20px;
  h1 {
    color: transparent;
  }
`;

export function DjBoxes() {
  const { usedVotes, isAuthenticated } = useAppContext();
  return (
    <>
      <DjGrid>
        <DjBox name={Artists_Enum.Dizzy} />
        <DjBox name={Artists_Enum.Gloria} />
        <DjBox name={Artists_Enum.Ika} />
        <DjBox name={Artists_Enum.Nikita} />
      </DjGrid>
      {isAuthenticated ? (
        <VotingNotice>
          Hai usato <b>{usedVotes}</b> dei tuoi <b>50</b> VOTI SETTIMANALI!{" "}
          <br />
          Clicca sul cuore vicino all’immagine profilo per votare la tua DJ
          preferita
        </VotingNotice>
      ) : (
        <Link to="/login">
          <VotingNotice>
            REGISTRATI O ACCEDI <b>PER VOTARE</b>
          </VotingNotice>
        </Link>
      )}
    </>
  );
}
export function KnowBetter() {
  const { isAuthenticated } = useAppContext();

  const [state, handleSubmit] = useForm("mleqvplz");
  if (state.succeeded) {
    return (
      <div style={{ zIndex: 500, position: "relative", margin: 50 }}>
        <h1>Grazie per aver partecipato!</h1>
      </div>
    );
  }

  return (
    <Container onSubmit={handleSubmit}>
      <FormToFill>
        <h1>CONOSCI LE DJ, O MEGLIO, LADY-J</h1>
        <p>
          Ascolta, guarda e vota la tua DJ preferita! Ti basterà cliccare il
          link qui sotto e creare un account LADY-J per avere tutto a portata di
          click. <br />
          <br />
          La classifica si aggiorna settimanalmente e il vostro supporto si
          unirà al giudizio della giuria tecnica durante la finale per decretare
          la vincintrice!
        </p>
        {!isAuthenticated && (
          <Link to="/login">
            <Button>
              REGISTRATI O ACCEDI <br /> <b>PER VOTARE</b>
            </Button>
          </Link>
        )}
      </FormToFill>

      <div>
        <DjBoxes />
      </div>
    </Container>
  );
}
