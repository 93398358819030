import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { Ranking } from "../vote/ranking";
import { DjBoxes } from "../knowBetter/knowBetter";
import { Logos } from "./logos";
import { Button } from "../components";
import { useAppContext } from "../context/context";
import { useAuth0 } from "@auth0/auth0-react";

const DjContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-image: url("/img/vote.webp");
  background-position: center;
  background-size: cover;
  width: 360px;
  height: 500px;
  color: var(--dj-white);
  text-align: center;
  padding: 100px 25px;
  border-radius: 10px;
  margin-right: auto;

  @media screen and (max-width: 1100px) {
    margin: 0;
    width: 300px;
  }
`;
const Container = styled.div`
  padding: 50px;
  padding-top: 150px;
  display: flex;
  justify-content: center;
  align-items: flex-start;

  @media screen and (max-width: 1100px) {
    align-items: center;
    flex-direction: column;
    gap: 30px;
    padding: 10px;
    padding-top: 150px;
  }
`;
const Accedi = styled.span`
  font-size: 25px;
  margin-left: 10px;
  font-weight: 700;
`;
const RankingContainer = styled.div`
  background-color: #ffffff79;

  padding: 20px;
  display: flex;
  gap: 30px;
  flex-direction: column;
  flex: 1;
  width: fit-content;
`;
const ShowSupport = styled.div`
  text-align: center;
`;
const RightContainer = styled.div`
  width: 100%;
  flex: 1;

  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;
export function LoginPage() {
  const { loginWithPopup } = useAuth0();
  return (
    <>
      <Container>
        <DjContainer>
          <div>
            <FontAwesomeIcon icon={faUserPlus} size="3x"></FontAwesomeIcon>
            <Accedi>ACCEDI </Accedi>
          </div>
          <div>
            <Button onClick={() => loginWithPopup()}>
              REGISTRATI O ACCEDI
            </Button>
          </div>
        </DjContainer>
        <RightContainer>
          <RankingContainer>
            <ShowSupport>
              <h2>SHOW YUOR SUPPORT</h2> Accedi per votare e supportare la tua
              DJ preferita!
            </ShowSupport>
            <Ranking />
          </RankingContainer>
          <DjBoxes />
        </RightContainer>
      </Container>
      <Logos />
    </>
  );
}
