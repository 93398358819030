import { Artists_Enum } from "../generated/graphql";

export type DjDetails = {
  name: string;

  img: string;
  description: string;
  instagram: string;
  soundcloud: string;
};

export const djDetails = new Map<Artists_Enum, DjDetails>();
djDetails.set(Artists_Enum.Dizzy, {
  name: "@dizzy",
  img: "/img/djpics/DIZZY.webp",
  description: `Mi chiamo Dizzy, sono nata nel 1998 e sono un'artista fiorentina e faccio parte del roster di Tenax, ma adesso vivo a Milano per motivi universitari. Ho iniziato il mio percorso musicale esplorando sonorità l'ambient e il downtempo, che utilizzavo come colonna sonora per i miei momenti di pittura. Questa passione è cresciuta oltre le mura del mio studio-garage, portandomi a suonare in prestigiose location come il Pikes di Ibiza e l’Hoppetosse di Berlino.
  
  La mia musica è un viaggio attraverso sonorità ibride che sfuggono a qualsiasi classificazione rigida. Nei miei set, mi piace mescolare trip hop, electro, wave e dub, creando un’esperienza sonora unica. Mi piace costruire i miei set come un crescendo, iniziando con warm-up delicati che avvolgono l’ascoltatore, per poi evolversi in momenti più intensi e coinvolgenti, conducendo l'udito verso dimensioni spesso inesplorate.
  
  Uno dei miei principali obiettivi è portare la musica più underground su canali più mainstream come quelli radiofonici, come Radio 105 che è uno dei main sponsor, per offrire agli ascoltatori un’esperienza di scoperta musicale. Credo sia fondamentale proporre qualcosa di diverso e innovativo, anziché ripetere brani noti e spesso ripetitivi nel carattere degli stessi, Inoltre, mi impegno a far conoscere e apprezzare il vinile, un formato che ritengo abbia un valore inestimabile per la sua qualità sonora e il suo fascino unico.
  
  Spero di poter influenzare positivamente le nuove generazioni e i DJ del futuro, offrendo loro una dimensione sonora inaspettata e poco conosciuta. Sono convinta che la musica abbia il potere di aprire nuove prospettive e stimolare la creatività, e desidero essere parte di questo processo di scoperta e innovazione.`,
  instagram: "https://www.instagram.com/dilezanghe/",
  soundcloud: "https://on.soundcloud.com/HLvVqFSSPNcx2vWP6/",
});
djDetails.set(Artists_Enum.Gloria, {
  name: "@gloria fregonese",
  img: "/img/djpics/GLORIA.webp",
  description: `Sono Gloria Fregonese, modella e dj veneta. Fin da piccola ho sempre sentito che la musica faceva parte di me, infatti cantavo e ho praticato danza e ginnastica artistica per molti anni. Dai 17 anni ho iniziato a fare la modella e qualche anno fa ho unito le mie due grandi passioni insieme: musica e moda. Vorrei far parte di questo progetto perché penso sia il format giusto per dare vita ai miei sogni, che sono già realtà, ma con questo contest potrebbe diventare ancora più grandi. Io vivo d’arte. 

  Vivo di questo!`,
  instagram: "https://www.instagram.com/gloriafregonese/",
  soundcloud: "https://on.soundcloud.com/J3A8FgsSfnSDqycf9",
});
djDetails.set(Artists_Enum.Ika, {
  name: "@ika faccioli",
  img: "/img/djpics/IKA.webp",
  description: `Le braccia disegnate dai vintage tattoo, lunghi capelli neri e tanta passione per la musica.  Federica Faccioli, è una talentuosa DJ della scena veronese.  Inizia a suonare nella primavera del 2017 per alcuni party in città, facendosi subito notare per il suo stile eclettico e raffinato.  Da settembre 2017 diventa dj resident al Berfi’s club di Verona, dove suonerà per tutta la stagione nella famosa privè room, dedicata ai suoni di tendenza, chiamata “Aquario”.  Nel 2018 diventa Ika Faccioli e inizia un percorso come producer, realizzando 2 singoli: VOSTOK 6.1 e IDEAS FROM MARS (Fams Label).  A giugno 2018 arriva la prima grande occasione di suonare insieme ad alcuni grandi nomi del dj clubbing internazionale.  Le viene infatti proposto di suonare al ROOKAFORTE FESTIVAL 2018 e Ika prende questa occasione al volo, aprendo la serata Richie Hawtin - CLOSE - ricevendo i complimenti dagli altri artisti che suoneranno dopo di lei: Ben Klock, Daniel Avery , Richie Hawtin e Joseph Capriati. Poche settimane più tardi, sempre a Verona , apre con un suo dj set il concerto del “King of Trap” SFERA EBBASTA.  Il 28 giugno 2019 sale su un altro palco importante, quello dei SUBSONICA, al ROOKAZOO MUSIC FESTIVAL, aprendo con un dj set recensito dal critico musicale del quotidiano veronese “L’Arena” Giulio Brusati, che lo ha definito perfetto per introdurre una band come i Subsonica.  Con l’estate 2019 matura la decisione di cambiare genere, abbandona i suoni di tendenza e approda nel mondo dance, a lei più congeniale, sempre dando ai suoi dj set un’impronta inconfondibile per stile e selezione. 
 
  Da ottobre 2019 è dj resident al DISCO LOVE, il nuovo locale di riferimento di Verona, dove diventa la regina della Main Room, dividendo la consolle con i grandi nomi della scena dance italiana e internazionale come Cristian Marchi, Mark & Kremont, Djs From Mars, Papete Winter Tour e prossimamente anche con Benny Benassi.  Negli ultimi approda nella spiaggia di Gallipoli per tutta l’ estate nella famosa discoteca Praja dove farà da resident ogni domenica.  Suonerà poi al Tropicana a Mykonos, in Croazia e in altre tante località.`,

  instagram: "https://www.instagram.com/ika_facciolidj/",
  soundcloud: "https://soundcloud.com/ika-faccioli",
});

djDetails.set(Artists_Enum.Nikita, {
  name: "@nikita voguel",
  img: "/img/djpics/NIKITA.webp",
  description: `Nikita Voguel è una produttrice e dj originaria dell'India, è ideatrice del Bohemien Club / We Festival e gestisce molti altri progetti , nei suoi set spazia tra darkwave, electro e techno, portando in pista un equilibrio perfetto di ritmo e melodia. Nikita si avvicina alla musica in giovanissima età studiando canto classico e costruendo il proprio gusto musicale con gli insegnamenti del padre, grande collezionista di dischi.Al momento Nikita vive in Italia, dove continua ad organizzare eventi con Bohemien Club ed è sempre più impegnata con i suoi dj set, spesso a Berlino, ormai seconda casa, dove ha anche iniziato una collaborazione con Slow Motion Records.`,

  instagram: "https://www.instagram.com/nikita_voguel/",
  soundcloud: "https://soundcloud.com/kyky-gyn-priva",
});
