import styled from "styled-components";
import { useAppContext } from "../context/context";
import { djDetails } from "../djPage/djDetails";
import { Link } from "react-router-dom";
const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-template-rows: repeat(4, 40px);

  color: black;

  line-height: 40px;
  font-size: 30px;
  grid-row-gap: 15px;
  @media screen and (max-width: 1000px) {
    font-size: 20px;
    grid-template-rows: repeat(4, 30px);
    line-height: 20px;
  }
`;

const LikeCounter = styled.div`
  background-image: url("/img/cuore.webp");
  background-size: contain;
  background-position: left;
  background-repeat: no-repeat;
  height: 40px;

  padding-left: 50px;
  text-align: right;
  margin-left: 40px;

  @media screen and (max-width: 1000px) {
    line-height: 30px;
    padding-left: 35px;
    margin-left: 5px;
    height: 30px;
  }
`;
const ArtistNamePic = styled.span<{
  img?: string;
}>`
  background-image: url(${(props) => props.img});
  background-size: contain;
  background-position: left;
  background-repeat: no-repeat;

  padding-left: 50px;
  margin-left: 20px;
  @media screen and (max-width: 1000px) {
    height: 30px;
    margin-left: 10px;
    line-height: 20px;
    padding-left: 30px;
  }
`;

export function Ranking() {
  const { rankingsMap } = useAppContext();
  const djs = Array.from(rankingsMap.entries());
  return (
    <Container>
      {djs.map(([dj, votes], index) => (
        <>
          <div>{index + 1}.</div>

          <Link to={"/dj/" + dj}>
            <ArtistNamePic img={djDetails.get(dj)?.img}>
              {djDetails.get(dj)?.name}
            </ArtistNamePic>
          </Link>
          <LikeCounter>{votes}</LikeCounter>
        </>
      ))}
    </Container>
  );
}
