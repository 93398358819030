import React from "react";
import styled from "styled-components";

const Container = styled.div`
  flex-direction: row;
  display: flex;
  gap: 10px;
  margin-top: 100px;
  margin-bottom: 50px;
  justify-content: space-evenly;

  & > div {
    width: 20vw;
    position: relative;
  }
  & img {
    width: 100%;
    max-width: 400px;
    z-index: 5;
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 12px;
  }
  @media screen and (max-width: 1000px) {
    flex-direction: column;
    gap: 80px;
    align-items: center;
    & > div {
      height: 100%;
      width: auto;
      margin-bottom: 80px;
    }
  }
`;

const OverlayBlock = styled.div`
  position: absolute;

  font-family: "Montserrat";
  color: var(--dj-white);
  font-size: 35px;
  font-style: bold;
  line-height: 30px;
  z-index: 5;
`;
const UpBLock = styled(OverlayBlock)`
  top: -80px;
  z-index: 5;
  line-height: 40px;
  b {
    font-size: 32px;
  }
`;
const DownBlock = styled.div`
  font-size: 30px;
  position: relative;
  z-index: 5;
  b {
    font-size: 25px;
  }
`;

const Title = styled.div`
  font-weight: 500;
  font-size: 55px;
  max-width: 1000px;
  padding: 30px;
  position: relative;
  z-index: 5;
`;
function LocationBlock(props: {
  img: string;
  date_up: string;
  city_up: JSX.Element | string;
  date_down: JSX.Element | string;
  disco_down: JSX.Element | string;
  location_down: JSX.Element | string;
}) {
  return (
    <div>
      <img src={`/img/locations/${props.img}.webp`}></img>
      <UpBLock>
        <>
          {props.date_up}
          <br /> <b>{props.city_up}</b>
        </>
      </UpBLock>
      <DownBlock>
        <>
          {props.date_down}
          <br /> <b>{props.disco_down}</b>
          <br />
          {props.location_down}
        </>
      </DownBlock>
    </div>
  );
}

export function Locations() {
  return (
    <div>
      <Title>seguici nei beach club lungo tutte le coste d’Italia!</Title>
      <Container>
        <LocationBlock
          img="domizia"
          city_up="BAIA DOMIZIA"
          date_up="14 Giugno"
          date_down="15 Giugno"
          location_down="Castel Volturno"
          disco_down="UMAYA"
        />
        <LocationBlock
          img="venezia"
          city_up="VENEZIA"
          date_up="21 Giugno"
          date_down="22 Giugno"
          location_down="Chioggia"
          disco_down={
            <>
              SAND BEACH <br /> SOTTOMARINA
            </>
          }
        />

        <LocationBlock
          img="genova"
          city_up="GENOVA"
          date_up="28 Giugno"
          disco_down="ESTORIL"
          date_down="29 Giugno"
          location_down="Genova"
        />
        <LocationBlock
          img="massa"
          city_up="MASSA CARRARA"
          date_up="5 Luglio"
          date_down="6 Luglio"
          location_down="Massa Carrara"
          disco_down="GIO' Beach"
        />
      </Container>
    </div>
  );
}
