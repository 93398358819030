import styled from "styled-components";

const Container = styled.div`
  text-align: left;
  img {
    width: 100%;
  }
  h1 {
    font-size: 2.9em;
    font-weight: 500;
  }
  margin-bottom: 50px;
`;
const TextContainer = styled.div`
  max-width: 1200px;
`;

export function NoFinishHere() {
  return (
    <Container>
      <TextContainer>
        <h1>
          non finisce qui... la vincitrice di LADY-J suonerà all’ Ibiza Global
          Festival!
        </h1>
        <p>
          Il weekend del 9 e 10 agosto l'evento a ingresso gratuito invaderà
          ancora una volta la spiaggia di S'Arenal a San Antonio!
          <br />
          <br /> Anche se la line-up è ancora gelosamente sorvegliata, possiamo
          garantire che sarà un’altra epica maratona dance, con i precedenti
          headliner tra cui Luciano, Nic Fanciulli, Roger Sanchez, Amémé, Alex
          Kennon e molti altri.
        </p>
      </TextContainer>
      <img src="/img/ibizaFestival.webp"></img>
    </Container>
  );
}
