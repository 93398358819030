import styled from "styled-components";
import { VideoSection } from "./videoSection";
import { DjBox } from "./djSocial";
import { djDetails } from "./djDetails";
import { Artists_Enum } from "../generated/graphql";
import { Link, useParams } from "react-router-dom";
import { useAppContext } from "../context/context";
const Container = styled.div`
  padding: 50px;
  padding-top: 150px;
  display: flex;
  flex-direction: row;
  gap: 50px;
  @media screen and (max-width: 1100px) {
    flex-direction: column;
    padding: 20px;
    padding-top: 150px;
  }
`;

const LeftColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  & > p {
    justify-content: justify;
    font-weight: 700;
    margin-top: 50px;
  }
`;
const RightColumn = styled.div`
  flex: 2;
`;

const LoginToSee = styled.div`
  background-color: #0000007e;
  text-align: center;
  max-width: 700px;
  line-height: 1.5;
  margin-top: 50px;
  h1 {
    font-weight: 500;
    font-size: 40px;
  }
  padding: 20px;
  button {
    color: var(--dj-white);
    background-color: transparent;
    border: 2px solid white;
    padding: 10px 30px;
    font-size: 20px;
    cursor: pointer;
  }
  h2 {
    font-weight: 400;
  }
`;
export function DjPage() {
  let { id } = useParams();
  const { isAuthenticated } = useAppContext();

  const dj = djDetails.get(id as Artists_Enum);
  if (!dj) return null;
  return (
    <Container>
      <LeftColumn>
        <DjBox dj={id as Artists_Enum}></DjBox>
        <p>{dj.description}</p>
      </LeftColumn>
      <RightColumn>
        <VideoSection title="selezioni" videoIds={["PF2Eoa9gQZI"]} />
        {isAuthenticated ? (
          <></>
        ) : (
          <LoginToSee>
            <h1>
              REGISTRATI O ACCEDI PER VOTARE e VEDERE I CONTENUTI VIDEO IN
              ESCLUSIVA!
            </h1>
            <Link to="/login">
              <button>CLICCA QUI!</button>
            </Link>
            <h2>ti basterà inserire la tua e-mail</h2>
          </LoginToSee>
        )}
      </RightColumn>
    </Container>
  );
}
