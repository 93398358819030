import styled from "styled-components";

const BgContainer = styled.div`
  height: 100vh;
  background-image: url(/img/landing/bg.webp);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  position: relative;
`;

const SlideTitle = styled.div`
  position: absolute;
  top: 50%;
  left: 40px;
  color: var(--dj-white);
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 50px;
  transform: translate(0, -50%);
  @media screen and (max-width: 800px) {
    font-size: 25px;
    left: 30px;
  }
`;

/* empowering women by celebrating the beauty of music */

export function LandingPage() {
  return (
    <>
      <BgContainer />
      <SlideTitle>
        empowering women by <br />
        celebrating the beauty of music
      </SlideTitle>
    </>
  );
}
/* export function LandingPage() {
  return (
    <>
      <BgContainer />
      <SlideTitle>
        empowering women by <br />
        celebrating the beauty of music
      </SlideTitle>
      <Logo />
    </>
  );
}
 */
